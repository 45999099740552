import React from "react";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import Section from "../components/section";
import params from "../data/params";

const LegalAdvice = (props) => {
    return <Layout staticHeader>
        <SEO title="Transparencia" description="" location={props.location}/>

        <Section title="Transparencia">
            <div className="container" style={{marginBottom: "45px"}}>
            <h3>Presentación de la empresa</h3>
            <p>Adventurees Alliance S.L. es una empresa ZEC, constituida en Canarias en 2016, que presta servicios a todas aquellas comunidades alrededor del mundo que apoyan el emprendimiento.</p>
            <p>De la mano de Adventurees Alliance, las universidades, escuelas de negocio, corporaciones, instituciones públicas y otras organizaciones, con o sin ánimo de lucro, pueden acceder a una variedad de soluciones tecnológicas, herramientas y know-how para impulsar la creación, el desarrollo y la financiación de las empresas de su entorno, y todo ellos de forma 100% digital.</p>
            <p>La propuesta de productos y servicios de Adventurees se puede resumir en dos grandes líneas:</p>
            <ol style={{listStyleType: "lower-alpha"}}>
            <li style={{marginBottom: "20px", marginTop: "20px"}}><strong>Licencias SaaS</strong> (Software as a Service) de plataformas digitales de emprendimiento en “marca blanca” y herramientas de desarrollo empresarial.</li>
            <li style={{marginBottom: "20px"}}><strong>Servicios </strong>de formación, consultoría y asesoramiento en desarrollo de negocio y gestión de ecosistemas de emprendimiento, con un know-how y metodologías  validados por años de experiencia.</li>
            </ol>
            <p>La empresa está en fase comercial y prestando servicios a numerosas comunidades de emprendimiento en España, EEUU y Latinoamérica. </p>
            <p> </p>
            <h3>Organigrama</h3>
            <p>La empresa está organizada en 3 áreas de actividad:</p>
            <ul>
            <li style={{marginBottom: "20px", marginTop: "20px"}}>Área comercial y marketing, coordinada por Stuart Rodríguez</li>
            <li style={{marginBottom: "20px"}}>Área de tecnología y operaciones, dirigida por Iván Domínguez</li>
            <li style={{marginBottom: "20px"}}>Área legal, contable y administrativa, externalizada a las asesorías ECIJA y Violán Asesores.</li>
            </ul>
            <br></br>
            <img src="/images/slides/transparency-organigram.jpg" alt="Transparency organigram"></img>
            <p>&nbsp;</p>
            <h3>Objeto Social</h3>
            <p>Tal y como queda recogido en sus estatutos, la sociedad tiene por objeto:</p>
            <ol>
            <li style={{marginBottom: "20px", marginTop: "20px"}}>Portales Web, CNAE 6312</li>
            <li style={{marginBottom: "20px"}}>Otras actividades de consultoría de gestión empresarial, CNAE 7022</li>
            </ol>
            <p>Las actividades enumeradas podrán ser desarrolladas por esta sociedad de modo indirecto, total o parcialmente, mediante la participación en otras sociedades con objeto idéntico o análogo. </p>
            </div>
        </Section>
    </Layout>
}

export default LegalAdvice;
